import React, { useState } from 'react';
import styles from './SignUp.module.css';
import logo from '../YourFlockLogo.png';
import { v4 as uuidv4 } from 'uuid';
// Add import for the useHistory hook
import { useNavigate } from 'react-router-dom';


const SignUp = () => {
    console.log('SignUp component is rendering');
    
    // Initialize useHistory
    const navigate = useNavigate();
    const [time, setTime] = useState('08:00');

    const handleChange = (event) => {
        setTime(event.target.value);
    };

    // Convert BST time to GMT for server
    const bstTime = time ? new Date(`1970-01-01T${time}Z`) : null; // 'Z' implies UTC time
    const gmtTime = bstTime ? new Date(bstTime.getTime() - 3600000) : new Date('1970-01-01T07:00:00Z');
    const serverTime = `${gmtTime.getUTCHours()}:${gmtTime.getUTCMinutes() < 10 ? '0' : ''}${gmtTime.getUTCMinutes()}`;

    // Generate a unique string (nonce)
const nonce = uuidv4();

// Include the original state (serverTime) and the unique nonce in the state parameter
const state = `${serverTime}-${nonce}`;


    const slackOAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${process.env.REACT_APP_SLACK_CLIENT_ID}&scope=app_mentions:read,im:history,chat:write,im:write,users:read,im:read,team:read,users.profile:read,users:read.email&redirect_uri=${process.env.REACT_APP_SLACK_REDIRECT_URI}&state=${state}`;

    const date = new Date();
    const localTime = date.getTime();
    const localOffset = date.getTimezoneOffset() * 60000;
    const utc = localTime + localOffset;
    const offset = 1; // GMT offset
    const bst = utc + 3600000 * offset;
    const bstDate = new Date(bst);

    const hours = Array.from({ length: 24 }, (x, i) => (i + bstDate.getHours()) % 24); // start at current GMT+1 hour
    const sortedHours = [...hours.slice(8), ...hours.slice(0, 8)]; // Move hours starting from 8:00 to the beginning

    const handleWhatsAppSignup = () => {
        navigate('/WhatsAppSignup', { state: { messageTime: time } });
    };

    return (
        <div className={styles.container}>
            <div className={styles.logoSpace}>
                <img src={logo} alt="Logo" className={styles.logo} />
            </div>
            <div className={styles.signupBox}>
                <h2 className={styles.header}>Raise your leadership skills in just 5 minutes a day with your AI Coach!</h2>
                <button onClick={handleWhatsAppSignup} className={styles.whatsappButton}>
                    Sign Up with WhatsApp 😍 
                </button>
                <button onClick={() => window.location.href = slackOAuthUrl} className={styles.slackButton}> Sign Up with Slack 🥳 </button>
                <div className={styles.selectContainer}>
                    <label className={styles.selectLabel}>
                        What time in the day would you like us to coach you? (time zone: BST):
                    </label>
                    <select name="messageTime" onChange={handleChange} className={styles.selectTime} value={time}>
                        {sortedHours.map((hour, index) => (
                            <option value={hour < 10 ? `0${hour}:00` : `${hour}:00`} key={index}>
                                {hour < 10 ? `0${hour}:00` : `${hour}:00`}
                            </option>
                        ))}
                    </select>
                </div>
                <p className={styles.terms}>
                    By signing up for Your Flock, you agree to our 
                    <a href='https://yourflock.co.uk/terms-and-conditions/' target='_blank' rel='noopener noreferrer' className={styles.termsLink}> Terms of Service </a> and 
                    <a href='https://yourflock.co.uk/privacy-policy/' target='_blank' rel='noopener noreferrer' className={styles.termsLink}> Privacy Policy.</a>
                </p>
            </div>
        </div>
    );
};

export default SignUp;